<template>
    <div>
        <div class="row">
            <div :class="{ 'col-xs-9': canCreate, 'col-xs-11': canCreate === false }">
                <div class="form-group">
                    <multiselect :value="selectedBillAccount"
                                 @input="showBillAccountForm"
                                 class="form-control"
                                 track-by="id"
                                 label="account_number"
                                 placeholder="Search by Account Number"
                                 open-direction="bottom"
                                 :options="billAccounts"
                                 :searchable="true"
                                 :loading="isLoading"
                                 :internal-search="false"
                                 :close-on-select="true"
                                 :options-limit="20"
                                 :limit="20"
                                 :limit-text="limitText"
                                 :max-height="600"
                                 :show-no-results="true"
                                 select-label=""
                                 deselect-label=""
                                 :allowEmpty="false"
                                 :preserve-search="true"
                                 :disabled="selectedBillAccount.isNew || disabled"
                                 @search-change="findBillAccounts">

                        <template slot="singleLabel" slot-scope="props">
                            <div v-if="props.option.account_number">
                                <div class="row">
                                    <div class="col-xs-6">
                                        Account number: <br />
                                        {{ props.option.account_number }}<br />
                                        Account type: <br />
                                        {{ ucfirst(props.option.account_type) }}
                                    </div>
                                    <div class="col-xs-6" v-if="props.option.address">
                                        Facility Address: <br />
                                        {{ props.option.address.address }}<br />
                                        {{ props.option.address.city }}, {{ props.option.address.state }} {{ props.option.address.zip }}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                Search by Account Number
                            </div>
                        </template>

                        <template slot="option" slot-scope="props">

                            <div class="row">
                                <div class="col-xs-6">
                                    Account number: <br />
                                    {{ props.option.account_number }}<br />
                                    Account type: <br />
                                    {{ props.option.account_type }}
                                </div>
                                <div class="col-xs-6" v-if="props.option.address">
                                    Facility Address: <br />
                                    {{ props.option.address.address }}<br />
                                    {{ props.option.address.city }}, {{ props.option.address.state }} {{ props.option.address.zip }}
                                </div>
                            </div>

                        </template>
                        <span slot="noResult">Oops! No accounts found.</span>
                    </multiselect>
                </div>
            </div>
            <div class="col-xs-3" v-if="canCreate">
                <button class="inputbutton1" :disabled="disabled" @click.prevent="showNewAccountCreate" style="font-size: 14px;">Create New</button>
            </div>
        </div>
        <p class="mt-4">&nbsp;</p>
        <p class="mt-4">&nbsp;</p>

        <bill-account-form
            v-if="selectedBillAccount.account_number || selectedBillAccount.isNew"
            :bill-account="selectedBillAccount"
            :form-configuration="billAccountFormConfiguration"
            @savedBillAccount="addBillAccountToList"
            @reset="reset"
            :disabled="formDisabled"
        ></bill-account-form>

    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import { debounce } from 'lodash';
    import str_utils from 'etap/mixins/str_utils';
    export default {

        mixins: [str_utils],

        components: {
            Multiselect,
        },

        props: {
            billAccountFormConfiguration: {
                required: true,
                type: Object,
            },
            disabled: {
                default: false,
                type: Boolean,
            }
        },

        data() {
            return {
                selectedBillAccount: {
                    address: {}
                },
                billAccounts: [],
                isLoading: false,
                currentQuery: '',
            }
        },

        inject: {
            projectId: {
                default() {
                    return null;
                }
            }
        },

        computed: {
            canCreate() {
                return this.$can('create', window.MAT.model_map.project_bill_account);
            },
            canUpdate() {
                return this.$can('update', window.MAT.model_map.project_bill_account);
            },
            formDisabled() {

                if(this.disabled) {
                    return true;
                }

                if(this.selectedBillAccount.isNew && this.canCreate) {
                    return false;
                }else if(this.selectedBillAccount.account_number && this.canUpdate) {
                    return false;
                }

                return true;
            }
        },


        methods: {
            limitText(count) {
                return `and ${count} other bill accounts`;
            },
            findBillAccounts(query) {

                this.isLoading = true;
                this.currentQuery = query;
                this.search();

            },
            search: debounce(function() {

                axios.get('/api/bill_accounts', {
                    params: {
                        project_id: this.projectId,
                        account_number: this.currentQuery
                    }
                }).then(response => {

                    if(response.data) {
                        this.billAccounts = response.data;
                        this.isLoading = false;
                    } else {
                        window.notify.message('Invalid response from server in trying to get bill accounts', 'error');
                    }

                }).catch( error => {
                    window.notify.apiError(error);
                });

            }, 600),
            showBillAccountForm(billAccount) {

                delete billAccount.id;
                billAccount.project_id = this.projectId
                this.selectedBillAccount = billAccount;

            },
            showNewAccountCreate() {

                this.selectedBillAccount = {
                    isNew: true,
                    project_id: this.projectId,
                    account_number: '',
                    account_type: '',
                    energy_usage: '',
                    energy_cost: '',
                    peak_usage: '',
                    address: {
                        address: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: '',
                        additional_zip: '',
                    }
                }
            },
            reset() {
                this.selectedBillAccount = {
                    address: {}
                }
            },
            addBillAccountToList(billAccount) {
                this.$emit('addBillAccountToList', billAccount);
            }
        }

    }
</script>
