<template>
    <div class="flex shadow-lg z-2000 bg-color2 p-3 color-white justify-between items-center">
        <div class="measuresummaryinlineblockleft flex justify-around text-center" v-if="!gettingMeasureSummary">
            <div class="flex flex-col">
                <div class="text-sm">{{savings_label}}</div>
                <div>{{ measureSummary.dollarsavings | format_dollars }}{{savings_units}}</div>
            </div>
            <div class="flex flex-col" v-if="measureSummary.project_bonus != 0">
                <div class="text-sm">{{bonus_label}}</div>
                <div>{{ measureSummary.project_bonus | format_dollars }}</div>
            </div>
            <div class="flex flex-col" v-if="measureSummary.project_cap != 0">
                <div class="text-sm">{{incentive_cap_label}}</div>
                <div>{{ (measureSummary.project_cap * -1) | format_dollars }}</div>
            </div>
            <div class="flex flex-col">
                <div class="text-sm">{{incentive_label}}</div>
                <div>{{ measureSummary.discount | format_dollars }}</div>
            </div>
            <div class="flex flex-col">
                <div class="text-sm">{{cust_cost_label}}</div>
                <div>{{ measureSummary.custcost | format_dollars }}</div>
            </div>
            <div class="flex flex-col">
                <div class="text-sm">{{payback_label}}</div>
                <div>{{ measureSummary.payback | format_decimals(1)  }}{{payback_units}}</div>
            </div>
        </div>
        <div v-else class="measuresummaryinlineblockleft flex justify-around text-center">
            <form-skeleton
                :fields="1"
            />
            <form-skeleton
                :fields="1"
            />
            <form-skeleton
                :fields="1"
            />
            <form-skeleton
                :fields="1"
            />
        </div>
        <div class="measuresummaryinlineblockright flex justify-between items-center">
            <div style="width: 25px">
                <div title="Click to delete selected measures"
                     v-show="projectMeasuresToDelete.length > 0"
                     @click="showDeletePMMeasuresModal"
                     class="measuredeleteiconlarge">
                </div>
            </div>
            <div title="Click on arrow symbol to expand all room details."
                id="measurepickerviewroomdetails"
                @click="roomsExpanded = !roomsExpanded"
                class="measurePickerIcon fa"
                :class="{ 'fa-chevron-up' : roomsExpanded, 'fa-chevron-down' : !roomsExpanded }"
                >
            </div>
            <div title="Click on eye symbol to see detailed savings and costs."
                id="measurepickerviewdetails"
                 @click="toggleProjectMeasureDetails"
                :class="{ 'measurepickerviewdetailsexiticon' : showProjectMeasureDetails, 'measurepickerviewdetailsicon' : !showProjectMeasureDetails }"
                >

            </div>
            <div id="measurespickerfullscreenwrapper" @click="measurePickerFullScreen">
                <div
                    title="Click on square to lock blue bar at the top of the screen."
                    id="measurepickerfullscreen"
                    class="measurepickerfullscreenicon">
                </div>
            </div>
        </div>
        <modal
                v-if="showPMDeleteModal"
                :isConfirm="true"
                confirmText="Confirm Delete"
                modalWidth="400px"
                @close="showPMDeleteModal = false"
                @confirmed="deleteSelectedMeasures">

            <span slot="header">Delete Measures</span>
            <div slot="body" id="measure-delete-dialog-text">Are you sure you want to delete a total of {{ projectMeasuresToDelete.length }} selected measures?</div>
        </modal>
    </div>
</template>
<script>
    import { format_decimals } from 'etap/filters/format_decimals'
    import { format_dollars } from 'etap/filters/format_dollars'
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    import {mapState, mapActions} from 'vuex';
    export default {

        mixins: [MeasurePickerUtils],

        data() {
            return {
                gettingMeasureSummary: true,
                showPMDeleteModal: false,
                savings_label: Lang.get('audit_tool.scope_of_work_summary_bar.savings'),
                savings_units: Lang.get('audit_tool.scope_of_work_summary_bar.savings_units'),
                bonus_label: Lang.get('audit_tool.scope_of_work_summary_bar.bonus'),
                incentive_cap_label: Lang.get('audit_tool.scope_of_work_summary_bar.incentive_cap'),
                incentive_label: Lang.get('audit_tool.scope_of_work_summary_bar.incentive'),
                cust_cost_label: Lang.get('audit_tool.scope_of_work_summary_bar.cust_cost'),
                payback_label: Lang.get('audit_tool.scope_of_work_summary_bar.payback'),
                payback_units: Lang.get('audit_tool.scope_of_work_summary_bar.payback_units')
            }
        },

        computed: {
            projectMeasuresToDelete() {
                return this.$store.getters['mp_project_measures/projectMeasuresToDelete'](this.mpid);
            },
            roomsExpanded: {
                get() {
                    return this.$store.getters['mp_layout/getRoomsExpanded'](this.mpid);
                },
                set(expanded) {
                    this.$store.commit('mp_layout/setRoomsExpanded', {mpid: this.mpid, expanded: expanded});
                }
            },
        },

        filters: {
            format_decimals,
            format_dollars
        },

        async created() {
            this.gettingMeasureSummary = true;
            await this.getMeasureSummary(this.mpid)
            setTimeout(() => {
                this.gettingMeasureSummary = false;
            }, 200);
        },

        methods: {
            ...mapActions('mp_layout', [
                'getMeasureSummary'
            ]),
            ...mapActions('mp_project_measures', [
                'addRoom'
            ]),
            toggleProjectMeasureDetails() {
                this.$store.commit('mp_layout/toggleProjectMeasureDetails', this.mpid);
            },
            measurePickerFullScreen() {
                this.$store.commit('mp_layout/toggleFullPicker', this.mpid);
            },
            showDeletePMMeasuresModal() {
                this.showPMDeleteModal = true;
            },
            deleteSelectedMeasures() {
                this.showPMDeleteModal = false;
                this.$store.dispatch('mp_project_measures/deleteSelectedMeasures', this.mpid);
            },
        },
    }
</script>
