<script>
import { parseQueryString } from "../../utils/parseQueryString";

export default {
    props: {
        formConfig: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            searchParams: {},
            // this parameter is to essentially keep the table from immediately showing and searching
            // instead waiting for he first time the user clicks search on the eligibility form
            hasSearched: false
        }
    },

    created() {
          this.searchParams = Object.assign({}, parseQueryString(window.location.search));
    },

    computed: {
        isSelectedProgramTrackEligible() {
            if(
                !this.eligibilityFeature ||
                !this.eligibilityFeature.enabled ||
                !this.eligibilityFeature.options.valid_program_track ||
                !Array.isArray(this.eligibilityFeature.options.valid_program_track) ||
                !this.$store.getters.validTracksForUser ||
                !this.searchParams.program_track_id ||
                !this.searchParams.program_year_id
            ) {
                return false
            }

            const validProgramTracks = this.$store.getters.validTracksForUser.filter(programTrack => {
                return Number(programTrack.id) === Number(this.searchParams.program_track_id) &&
                    Number(programTrack.pivot.program_year_id) === Number(this.searchParams.program_year_id) &&
                    this.eligibilityFeature.options.valid_program_track.find(vPT => {
                        return vPT.program_track === programTrack.name;
                    }) !== undefined
            });

            return validProgramTracks.length > 0;
        },
        eligibilityFeature() {
            return window.MAT.features.find(feature => {
                return feature.name === 'eligibility_check';
            });
        },
    },

    methods: {
        handleFilterList(data) {
            this.hasSearched = true;

            // ensure page isn't passed to the table, the table will handle what page we're on
            delete data.page;
            this.searchParams = data;
        },
    }
}
</script>
<template>
    <div class="w-full">
        <div v-if="isSelectedProgramTrackEligible">
            <eligibility-form
                :form-config="formConfig"
                :search-params="searchParams"
                @filter-list="handleFilterList"
            ></eligibility-form>
            <eligibility-list
                v-if="hasSearched"
                :search-params="searchParams"
            ></eligibility-list>
        </div>
        <div v-else class="warning text-red p-4 text-left">
            The selected program track and program year are not valid for looking up eligible customers.
        </div>
    </div>
</template>
