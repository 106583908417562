<script>
import {
    faCheck,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default {
    props: {
        user: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            enablingTwoFactorAuth: false,
            qrCode: '',
            confirmCode: '',
            confirmed: false,
            confirmedError: '',
            recoveryCodes: {},
            faCheck,
            faTimes
        }
    },

    methods: {
        async enableTwoFactorAuth() {
            try {
                if (this.user.two_factor_enabled) {
                    throw new Error("Two factor authentication already enabled...");
                }
                await axios.post("/api/auth/user/two-factor-authentication", {});
                const qrCodeResponse = await axios.get("/api/auth/user/two-factor-qr-code")

                if (!qrCodeResponse.data) {
                    throw new Error("Unable to get QR code");
                }
                this.qrCode = qrCodeResponse.data.svg;
                await this.getRecoveryCodes();

            } catch (err) {
                console.log(err);
                window.notify.error("Unable to enable two factor authentication: " + err.toString(), 'Error');
            }
        },
        async showRecoveryCodes() {
            try {
                if (!this.user.two_factor_enabled) {
                    throw new Error("User doesn't have two factor enabled");
                }
                await this.getRecoveryCodes();
            } catch (err) {
                window.notify.error(err.toString(), 'Error');
            }
        },
        async confirm2FACode() {
            try {
                const confirmedResponse = await axios.post("/api/auth/user/confirmed-two-factor-authentication", {
                    code: this.confirmCode
                });

                if (confirmedResponse.status !== 200) {
                    throw new Error("Unable to confirm 2FA code");
                }
                this.confirmed = true;


                setTimeout(() => {
                    window.location.reload(true);
                })

            } catch (err) {
                console.log(err);
                window.notify.apiError(err);
            }
        },
        async disable2FA() {
            try {
                if (!this.user.two_factor_enabled) {
                    throw new Error("User doesn't have two factor enabled");
                }

                console.log('disabling');

                const disableResponse = await axios.delete("/api/auth/user/two-factor-authentication");

                window.notify.success('Disabled two factor authentication')

                setTimeout(() => {
                    window.location.reload(true);
                })
            } catch (err) {
                window.notify.apiError(err);
            }
        },
        async getRecoveryCodes() {
            const recoveryCodesResponse = await axios.get("/api/auth/user/two-factor-recovery-codes");
            this.recoveryCodes = recoveryCodesResponse.data;
        },
        cancelSetup() {
            this.qrCode = ''
            this.recoveryCodes = {}
        }
    },
};
</script>
<template>
    <div v-if="user" class="mt-8">
        <div class="w-full md:w-1/2 p-2">
            <h3 class="font-bold text-2xl">Two Factor Authentication</h3>
            <p>
                Manage your two-factor authentication settings to add additional
                security to your account using two factor authentication.
            </p>
        </div>
        <div class="m-2 md:m-4">


            <div>

                <div v-if="qrCode">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">
                            Two factor authentication is now enabled. Scan the following QR code
                            using your phone's authenticator application.
                        </p>
                    </div>

                    <div class="mt-4" v-html="qrCode"></div>
                </div>

                <div v-if="recoveryCodes.length > 0">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">
                            Store these recovery codes in a secure password manager. They can be
                            used to recover access to your account if your two factor
                            authentication device is lost.
                        </p>
                    </div>

                    <div
                        class="
                        grid-container
                        mt-4
                        px-4
                        py-4
                        max-w-half
                        font-mono
                        text-sm
                        bg-gray-100
                        rounded-lg
                      "
                    >
                        <div v-for="code in recoveryCodes" :key="code" class="grid-item">
                            {{ code }}
                        </div>
                    </div>
                </div>


                <div v-if="!user.two_factor_confirmed_at && (user.two_factor_enabled || qrCode)">

                    <span class="font-bold text-red-800">
                        You have not yet confirmed your 2FA.  If you don't confirm it, you may be locked out of your account
                    </span>

                    <form-text
                        field-name="confirm-2fa-code"
                        label="Enter a code to confirm your 2FA Authentication "
                        v-model="confirmCode"
                        class="w-full mt-2"
                    ></form-text>

                    <div v-if="confirmed" class="text-green-400">
                        <font-awesome-icon :icon="faCheck"></font-awesome-icon>&nbsp;2FA Code Confirmed
                    </div>
                    <div v-else-if="confirmedError.length > 0" class="text-red-500">
                        <font-awesome-icon :icon="faTimes"></font-awesome-icon>&nbsp; {{ confirmedError }}
                    </div>
                </div>
                <div v-else-if="user.two_factor_enabled && user.two_factor_confirmed_at" class="text-green-400 mt-2">
                    <font-awesome-icon :icon="faCheck"></font-awesome-icon>&nbsp;2FA Code Confirmed & Enabled
                </div>

                <div class="flex" v-if="user.two_factor_enabled || qrCode">
                    <div v-if="!user.two_factor_confirmed_at">
                        <confirm-password-action
                            button-text="Confirm 2FA"
                            @confirmed="confirm2FACode"
                            class="mr-2"
                        >
                        </confirm-password-action>
                    </div>

                    <confirm-password-action
                        v-if="user.two_factor_confirmed_at"
                        button-text="Show Recovery Codes"
                        @confirmed="showRecoveryCodes"
                        class="mr-2"
                    >
                    </confirm-password-action>

                    <confirm-password-action
                        v-if="user.two_factor_confirmed_at"
                        button-text="Disable 2FA"
                        @confirmed="disable2FA"
                    >
                    </confirm-password-action>

                    <button
                        v-if="!user.two_factor_confirmed_at"
                        class="button" @click='cancelSetup'
                    >
                        Cancel
                    </button>
                </div>

            </div>

            <div class="mt-4">
                <confirm-password-action
                    v-if="user.two_factor_enabled === false && !this.qrCode"
                    button-text="Enable two-factor authentication"
                    @confirmed="enableTwoFactorAuth"
                >
                </confirm-password-action>
            </div>
        </div>
    </div>
</template>
<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 10px;
}

.grid-item {
    padding: 1px;
}

.bg-gray-100 {
    background-color: rgba(220, 208, 208, 0.82);
}

.max-w-half {
    max-width: 50%;
}

.font-mono {
    font-family: monospace;
}
</style>
