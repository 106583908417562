<template>
    <form-select
        :field-name="fieldConfig.fieldName"
        :value="value"
        @input="updateValue"
    ></form-select>
</template>

<script>
    import { FormFieldMixin, HasOptionsMixin, assignOnObject, byString } from 'laravel-vue-forms';
    import axios from 'axios';
    import Parser from 'etap/admin/classes/jsonapi_parser';

    export default {
        mixins: [
            FormFieldMixin,
            HasOptionsMixin
        ],

        mounted: function() {
            this.updateFieldsOnForm(this.value);
        },

        methods: {
            updateValue(value) {
                this.updateFieldsOnForm(value);
                this.$emit('input', value);
            },
            updateFieldsOnForm(companyId) {
                if(!this.fieldConfig.field_extra.update_form_fields) {
                    return;
                }

                // only update if condition is met
                var condition = this.fieldConfig.field_extra.update_form_fields.condition;
                var conditionalValue = byString(this.form.data, condition.valueField)
                if(conditionalValue === condition['fieldValue'])
                {
                    if(companyId === null) {
                        this.fieldConfig.field_extra.update_form_fields.updates.forEach(data => {
                            assignOnObject(
                                this.form.data,
                                data.field,
                                null
                            );
                        });
                    } else {
                        axios.get('/api/crm/companies/' + companyId, {
                            params: {
                                'include': 'company_address'
                            }
                        }).then( response => {
                            if(response.data)
                            {
                                var record = Parser.parseJSONAPIResponse(response.data);

                                this.fieldConfig.field_extra.update_form_fields.updates.forEach(data => {
                                    assignOnObject(
                                        this.form.data,
                                        data.field,
                                        byString(record, data.valueKey)
                                    );
                                });
                            }
                        }).catch(error => {
                            window.notify.apiError(error);
                        });
                    }
                }
            }
        }
    };
</script>
