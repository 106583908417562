import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// import sub-modules


import FromConfigurations from 'etap/store/form_configurations/module';
import Checklists from 'etap/store/checklists/module';
import MeasurePickerEquipment from 'etap/store/measure_picker_equipment/module';
import MeasurePickerLayout from 'etap/store/measure_picker_layout/module';
import MeasurePickerProjectMeasures from 'etap/store/measure_picker_project_measures/module';
import MeasurePickerScenarios from 'etap/store/measure_picker_scenarios/module';
import MeasurePickerTechnology from 'etap/store/measure_picker_technology/module';
import EquipmentLibrary from 'etap/store/equipment_library/module';
import QCProgress from 'etap/store/qc_progress/module';
import QCRollup from 'etap/store/qc_rollup/module';
import Projects from 'etap/store/projects/module';
import ProjectPayments from 'etap/store/project_payments/module';
import Inspections from 'etap/store/inspections/module';
import ProjectOperatingSchedule from 'etap/store/project_operating_schedule/module';
import ProjectLeads from 'etap/store/project_leads/module';
import CustomerInformation from 'etap/store/customer_information/module';
import BillAccounts from 'etap/store/bill_accounts/module';
import System from 'etap/store/system/module';
import EnergyDashboard from "etap/store/energy_dashboard/module";
import Jobs from 'etap/store/jobs/module';
import IncentiveDashboard from 'etap/store/incentive_dashboard/module';
import LookupStore from 'etap/store/lookups/module';

import Admin from 'etap/store/admin/module';

export const store = new Vuex.Store({
    modules: {
        form_configurations: FromConfigurations,
        checklists: Checklists,
        equipment_library: EquipmentLibrary,
        mp_equipment: MeasurePickerEquipment,
        mp_layout: MeasurePickerLayout,
        mp_project_measures: MeasurePickerProjectMeasures,
        mp_scenarios: MeasurePickerScenarios,
        mp_technology: MeasurePickerTechnology,
        projects: Projects,
        project_payments: ProjectPayments,
        inspections: Inspections,
        project_operating_schedule: ProjectOperatingSchedule,
        project_leads: ProjectLeads,
        customer_information: CustomerInformation,
        bill_accounts: BillAccounts,
        energy_dashboard: EnergyDashboard,
        jobs: Jobs,

        admin: Admin,

        qc_rollup: QCRollup,
        qc_progress: QCProgress,
        system: System,
        incentive_dashboard: IncentiveDashboard,
        lookup: LookupStore,
    },
    state: {
        apiPrefix: '/api/',
        projectPhases: [],
        programTracks: [],
        validTracksForUser: [],
        programYears: [],
        lookups: []
    },
    mutations: {
        setProjectPhases(state, projectPhases) {
            state.projectPhases = projectPhases;
        },
        setProgramTracks(state, programTracks) {
            state.programTracks = programTracks;
        },
        setValidTracksForUser(state, validTracksForUser) {
            state.validTracksForUser = validTracksForUser;
        },
        setProgramYears(state, programYears) {
            state.programYears = programYears;
        },
        setLookups(state, lookups) {
            state.lookups = lookups;
        }
    },
    getters: {
        programTracks(state) {
            return state.programTracks
        },
        validTracksForUser(state) {
            return state.validTracksForUser;
        },
        uniqueValidTracksForUser(state) {
            return Array.from(new Set(state.validTracksForUser.map(vtfr => vtfr.id)))
                .map(id => {
                    return state.programTracks.find(t => t.id === id);
                });
        },
        programYears(state) {
            return state.programYears
        },
        getProgramTrackForId(state) {
            return function(programTrackId) {

                if(state.programTracks === null) {
                    return null;
                }

                return state.programTracks.find(track => {
                    return track.id === programTrackId;
                })
            }
        },
        getProgramTrackForName(state) {
            return function(name) {

                if(state.programTracks === null) {
                    return null;
                }

                return state.programTracks.find(track => {
                    return track.name === name;
                })
            }
        },
        visibleProjectPhasesForProgramTrack(state, getters) {
            return function (programTrackId) {
                let programTrack = getters.getProgramTrackForId(programTrackId);

                if(!programTrack) {
                    return [];
                }

                return programTrack.project_phases.filter(phase => {
                    return phase.hidden === 0;
                });
            }
        },
        getProjectPhaseForId(state) {
            return function(phaseId) {
                return state.projectPhases.find(phase => {
                    return phase.id === phaseId;
                });
            }
        },
        getProjectPhaseForName(state) {
            return function(name) {
                return state.projectPhases.find(phase => {
                    return phase.name === name;
                })
            }
        },
        getProgramYearForId(state) {
            return function(programYearId) {
                return state.programYears.find(programYear => {
                    return programYear.id === programYearId;
                });
            }
        },
        getProgramYearForName(state) {
            return function(name) {
                return state.programYears.find(programYear => {
                    return programYear.program_year === name;
                })
            }
        },
        getLookupForModel(state) {
            return function(model) {
                return state.lookups[model];
            }
        }
    },
    strict: true
});
