
import Vue from 'vue';
import Vuex from 'vuex';
import Acl from 'etap/plugins/acl';
import Features from 'etap/plugins/features';
import LaravelVueForms from 'laravel-vue-forms';
import VGrid from 'v-grid';
import { ApiClient } from 'etap/admin/apiClient';

Vue.use(Vuex);
Vue.use(Acl);
Vue.use(Features);

import { store } from './store/mat'

import 'etap/mat-global-bootstrap';
import 'etap/mat-legacy-jquery';
import 'bootstrap-sass';
import './bootstrappers/mat-base-components-bootstrap';

// make sure global dependencies are bootstrapped before bootstrapping Laravel Vue Forms
Vue.use(LaravelVueForms);
Vue.use(VGrid);

let apiClient = new ApiClient();
window.axios = apiClient;
Vue.prototype.$api = apiClient

new Vue({
    store,
    computed: {
        isSubmitted() {

            if(typeof window.isSubmitted === 'function') {
                return window.isSubmitted();
            }else {
                return false;
            }
        }
    },

    created() {
        if(window.MAT.program_tracks) {
            this.$store.commit('setProgramTracks', window.MAT.program_tracks);
        }
        if(window.MAT.valid_tracks_for_user) {
            this.$store.commit('setValidTracksForUser', window.MAT.valid_tracks_for_user);
        }
        if(window.MAT.project_phases) {
            this.$store.commit('setProjectPhases', window.MAT.project_phases);
        }
        if(window.MAT.program_years) {
            this.$store.commit('setProgramYears', window.MAT.program_years);
        }
        if(window.MAT.lookups) {
            this.$store.commit('setLookups', window.MAT.lookups);
        }
        if (window.MAT.features) {
            this.$store.commit('system/setFeatures', window.MAT.features);
        }
    }

}).$mount('#app');
