<template>
    <div class="form-group flex items-center" id="thermostat-widget-form">
        <div class="maxwidth thermostatwidgetrow">

            <div class="text-center">
                <div class="flex justify-around">
                    <h3 class="mr-4">
                        Thermostat Space information
                    </h3>
                    <h3 class="ml-4">
                        Proposed Thermostat Setbacks
                    </h3>
                </div>
                <div class="flex justify-around">
                    <div class="flex flex-col w-third">
                            <form-text
                                field-name="floor_area"
                                v-model="floor_area"
                            ></form-text>
                            <form-text
                                field-name="days_closed"
                                label="Days Closed"
                                v-model="days_closed"
                            ></form-text>
                            <form-text
                                field-name="set_back_hours"
                                label="Setback Hours"
                                v-model="set_back_hours"
                            ></form-text>
                    </div>
                    <div class="flex flex-col w-third">
                        <form-text
                            field-name="heating_set_back_degrees"
                            label="Heating Setback Degrees"
                            v-model="heating_set_back_degrees"
                        ></form-text>
                        <form-text
                            field-name="cooling_set_back_degrees"
                            label="Cooling Setback Degrees"
                            v-model="cooling_set_back_degrees"
                        ></form-text>
                    </div>

                </div>
            </div>

            <div class="w-full text-right pr-4 pt-4">
                <button class="inputbutton1" @click="$emit('close-modal')">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        inject: {
            form: {
                default() {
                    return {
                        disabled: false,
                        errors: new FormErrors()
                    }
                }
            },
            apiClient: {
                default() {
                    return axios;
                }
            }
        },

        computed: {
            floor_area: {
                get() {
                    return this.form.data.floor_area;
                },
                set(newFloorArea) {
                    this.form.data.floor_area = newFloorArea;
                },
            },
            days_closed: {
                get() {
                    return this.form.data.days_closed;
                },
                set(newDaysClosed) {
                    this.form.data.days_closed = newDaysClosed;
                },
            },
            set_back_hours: {
                get() {
                    return this.form.data.set_back_hours;
                },
                set(newSetBackHours) {
                    this.form.data.set_back_hours = newSetBackHours;
                },
            },
            heating_set_back_degrees: {
                get() {
                    return this.form.data.heating_set_back_degrees;
                },
                set(newDegreeSetback) {
                    this.form.data.heating_set_back_degrees = newDegreeSetback;
                },
            },
            cooling_set_back_degrees: {
                get() {
                    return this.form.data.cooling_set_back_degrees;
                },
                set(newDegreeSetback) {
                    this.form.data.cooling_set_back_degrees = newDegreeSetback;
                }
            },
        }
    }
</script>
