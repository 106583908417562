<template>
    <div id="thermostat-widget" class="form-group">
        <div class="flex items-center"
             :id="fieldName + '-select-field'"
             :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }">
            <div class="flex justify-between" style="width: 94%;">
                <label class="form-control-label"><span v-html="fieldConfig.label"></span>
                    <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
                    <span v-if="withHelpIcon" :class="fieldConfig.field_extra.withIcon" :title="fieldConfig.field_extra.helpText"></span>
                </label>
                <div>
                    <button style="padding: 10px;" @click="showModal = true"><span class="fa fa-edit"></span></button>
                </div>
            </div>
            <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
            </span>
            <div v-if="hasHelpText">
                <span v-html="fieldConfig.field_extra.helpText"></span>
            </div>
        </div>
        <div v-if="displays1" class="equipment-version-information" v-bind:style=" hasErrors ? 'border: 1px solid red;' : '' ">
            <div class="flex">
                <div class="w-half">
                    <div v-for="displayItem in displays1" class="flex align-items-center">
                        <label class="m-2">{{ displayItem.label }}:</label>
                        <span class="m-2">{{ form.data[displayItem.field] }}</span>
                    </div>
                </div>
                <div class="w-half">
                    <div v-for="displayItem in displays2" class="flex align-items-center">
                        <label class="m-2">{{ displayItem.label }}:</label>
                        <span class="m-2">{{ form.data[displayItem.field] }}</span>
                    </div>
                </div>
            </div>
        </div>
        <span class="errors" v-if="hasErrors">
            <ul style="list-style-type:none;">
              <li v-for="error in getErrors">
                {{ error }}
              </li>
            </ul>
        </span>
        <modal
            v-if="showModal"
            modalWidth="75%"
            @close="showModal = false"
        >
            <span slot="header">&nbsp;</span>

            <slot slot="body">
                <thermostat-adjustment-form @close-modal="showModal=false"></thermostat-adjustment-form>
            </slot>
        </modal>
    </div>
</template>
<script>
    import { FormFieldMixin } from 'laravel-vue-forms';

    export default {

        mixins: [
            FormFieldMixin
        ],

        data() {
            return {
                showModal: false,
                thermostatAdjustmentFormConfiguration: null,
                thermostatAdjustmentData: null,
            }
        },

        computed: {
            displays1() {
                if(!this.fieldConfig.field_extra.displays || !Array.isArray(this.fieldConfig.field_extra.displays)) {
                    return null;
                }

                let halfLength = Math.ceil(this.fieldConfig.field_extra.displays.length /2);
                return this.fieldConfig.field_extra.displays.slice(0, halfLength);
            },
            displays2() {
                if(!this.fieldConfig.field_extra.displays || !Array.isArray(this.fieldConfig.field_extra.displays)) {
                    return null;
                }

                let halfLength = Math.ceil(this.fieldConfig.field_extra.displays.length /2);
                return this.fieldConfig.field_extra.displays.slice(halfLength, this.fieldConfig.field_extra.displays.length );
            },
            hasErrors() {
                return (
                    ('heating_set_back_degrees' in this.form.errors.fieldErrors) ||
                    ('cooling_set_back_degrees' in this.form.errors.fieldErrors) ||
                    ('set_back_hours' in this.form.errors.fieldErrors) ||
                    ('days_closed' in this.form.errors.fieldErrors) ||
                    ('floor_area' in this.form.errors.fieldErrors)
                )
            },
            getErrors() {
                let fields = ["heating_set_back_degrees",
                    "cooling_set_back_degrees",
                    "set_back_hours",
                    "days_closed",
                    'floor_area'
                ];

                //Get all form errors and return the messages for just this component
                let errors = Object.entries(this.form.errors.fieldErrors).map(([key, value]) => ({key,value}));
                return errors.filter(error=>fields.includes(error.key)).map(error=>error.value).flat();
            }
        },
    }
</script>
