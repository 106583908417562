<template>
    <div class="row">
        <div class="col-xs-11">
            <div class="form-group" id="eligibility-form">
                <vue-form
                    :form-config="formConfig"
                    :form-data="searchParams"
                    :actions="formActions"
                    @eligibility-search="handleEligibilitySearch"
                ></vue-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        formConfig: {
            required: true,
            type: Object
        },
        searchParams: {
            required: true,
            type: Object
        }
    },

    data(){
        return {
            formActions: [
                {
                    name: 'eligibility_search',
                    label: 'Search',
                    action: 'eligibility-search'
                },
            ]
        }
    },
    methods: {
        handleEligibilitySearch(data) {
            this.$emit('filter-list', data);
        },
    }
}
</script>
<style>
#eligibility-form .vue-form .form-group {
    margin: 0px;
}
#eligibility-form .vue-form .form-group .form-control {
    margin: 0px;
}
#eligibility-form .vue-form .form-group .form-control-label {
    margin: 5px 0px;
}
</style>
