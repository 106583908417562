<template>
    <div>
        <preview-pdf
            :preview-url="finalSelectionUrl"
            :download-url="finalSelectionDownloadUrl"
            open-label="Preview Final Selection Form"
            class="mb-4"
            :disabled="!canPreviewAndGenerate"
        ></preview-pdf>
        <adobe-sign-request
            v-if="canPreviewAndGenerate && useAdobeSign"
            model-type="project"
            :model-id="project.id"
            agreement-type="final-selection-form"
            :additional-args="{phase: reportPhase.id, scenario: reportScenarioId}"
            generate-text="Generate Final Selection Form"
            regenerate-text="Re-generate Final Selection Form"
            sign-text="Sign Final Selection Form"
        ></adobe-sign-request>
        <div v-if="!canPreviewAndGenerate">
            <span v-html="trans('audit_tool.project_workflow.report_validation_messages.final_selection_form')"></span>
        </div>
    </div>
</template>
<script>
    import ValidateFinalSelectionFormGeneration from './../../mixins/validate_final_selection_form_generation.js';

    export default {
        mixins: [
            ValidateFinalSelectionFormGeneration
        ],

        props: {
            reportPhase: {
                type: Object,
            },
            reportScenarioId: {
                type: Number,
            },
            project: {
                required: true,
            },
            useAdobeSign: {
                type: Boolean,
                default: false,
            },
            currentScenario: {
                required: true,
                type: Object
            },
        },

        data() {
            return {
                canPreviewAndGenerate: false,
            }
        },

        created() {
            this.validateFinalSelectionFormGeneration().then(success => {
                this.canPreviewAndGenerate = true;
            }).catch( error => {
                if(error !== 'apiError') {
                    this.canPreviewAndGenerate = false;
                }
            });
        },

        computed: {
            finalSelectionUrl() {
                return '/project/' + this.project.id + '/generate_final_selection?action=view&type=final_selection_form';
            },
            finalSelectionDownloadUrl() {
                return '/project/' + this.project.id + '/generate_final_selection?action=download&type=final_selection_form';
            },
            scenarioRooms() {
                return this.$store.getters['mp_project_measures/getScenarioRoomsForPhase'](this.currentScenario, this.reportPhase)
            },
        },

        watch: {
            scenarioRooms: {
                handler(newRoom) {
                    this.validateFinalSelectionFormGeneration().then(success => {
                        this.canPreviewAndGenerate = true;
                    }).catch( error => {
                        if(error !== 'apiError') {
                            this.canPreviewAndGenerate = false;
                        }
                    });
                },
                deep: true,
            }
        },
    }
</script>
