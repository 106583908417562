 <template>
    <div class="bill_account row">
        <div class="col-sm-4">
            <div class="form-group">
                <label>Account Number:</label>
                {{ billAccount.account_number }}
            </div>
            <div class="form-group">
                <label>Bill Account Name:</label><br />
                {{ billAccount.bill_name }}
            </div>
            <div class="form-group">
                <label>Account Type:</label>
                {{ billAccountTypeLabel }}
            </div>
            <div class="form-group" v-if="billAccount.account_type === 'electric_and_gas' || billAccount.account_type === 'electric'">
                <label>Electric Energy Usage:</label>
                {{ billAccount.electric_energy_usage }}
            </div>
            <div class="form-group" v-if="billAccount.account_type === 'electric_and_gas' || billAccount.account_type === 'electric'">
                <label>Electric Energy Cost:</label>
                {{ billAccount.electric_energy_cost }}
            </div>
            <div class="form-group" v-if="billAccount.account_type === 'electric_and_gas' || billAccount.account_type === 'gas'">
                <label>Gas Energy Usage:</label>
                {{ billAccount.gas_energy_usage }}
            </div>
            <div class="form-group" v-if="billAccount.account_type === 'electric_and_gas' || billAccount.account_type === 'gas'">
                <label>Gas Energy Cost:</label>
                {{ billAccount.gas_energy_cost }}
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group" v-if="hasPeakUsageField">
                <label>Peak Usage:</label>
                {{ billAccount.peak_usage }}
            </div>
            <div class="form-group" v-if="billAccount.address">
                <label>Address:</label><br />
                {{ billAccount.address.address }}<br />
                <span v-if="billAccount.address.address2">{{ billAccount.address.address2 }}<br /></span>
                {{ billAccount.address.city }}, {{ billAccount.address.state }} {{ billAccount.address.zip }}
                <span v-if="billAccount.address.additional_zip">
                    -{{ billAccount.address.additional_zip}}
                </span>
            </div>
            <div class="form-group">
                <label>Primary Bill Account:</label>
                <span v-if="billAccount.primary" class="fa fa-check-square-o"></span>
                <span v-else class="fa fa-square-o"></span>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group actions">
                <button v-if="canUpdate" @click.prevent="showModal" :disabled="disabled" class="inputbutton1">Edit</button><br />
                <button @click.prevent="removeBillAccount" :disabled="disabled" class="inputbutton1">Remove</button>
            </div>
        </div>

        <modal
            v-if="showEditModal"
            modalWidth="60%"
            @close="showEditModal = false">

            <span slot="header">Edit Bill Account</span>

            <slot slot="body">
                <bill-account-form
                    :bill-account="billAccount"
                    :disabled="disabled"
                    @savedBillAccount="updateBillAccount"
                    @close="showEditModal=false"></bill-account-form>
            </slot>
        </modal>

    </div>
</template>
<script>
    import axios from 'axios';
    import str_utils from 'etap/mixins/str_utils'
    export default {

        mixins: [str_utils],

        props: {
            billAccount: {
                required: true,
                type: Object
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        inject: {
            projectId: {
                default() {
                    return null;
                }
            },
            billAccountFormConfiguration: {
                default() {
                    console.error('Invalid Form Configuration provided');
                    return null;
                }
            }
        },

        data() {
            return {
                showEditModal: false,
                originalBillAccount: this.billAccount
            }
        },

        computed: {
            canUpdate() {
                return this.$can('update', window.MAT.model_map.project_bill_account);
            },
            hasPeakUsageField() {
                var hasField = false;
                this.billAccountFormConfiguration.fields.forEach(field => {
                    if(field.value_field === 'peak_usage' && field.visible !== 0 ) {
                        hasField = true;
                    }
                });

                if(this.billAccount.account_type === 'gas') {
                    hasField = false;
                }

                return hasField;
            },
            billAccountTypeLabel() {
                // TODO: migrate bill account types to lookups

                switch(this.billAccount.account_type) {
                    case 'electric':
                        return 'Electric';
                        break;
                    case 'gas':
                        return 'Gas';
                        break;
                    case 'electric_and_gas':
                        return 'Electric & Gas';
                        break;
                }

                return '';
            }
        },

        methods: {
            showModal() {
                this.showEditModal = true;
            },
            updateBillAccount(billAccount) {
                this.showEditModal = false;
                this.$emit('updateBillAccount', billAccount)
            },
            removeBillAccount() {

                if(this.disabled) {
                    return;
                }

                axios.delete('/api/bill_accounts/project/' + this.projectId, {
                    data: {
                        projectBillAccountId: this.billAccount.id
                    }
                }).then( response => {

                    this.$emit('deleteBillAccount', this.billAccount);

                }).catch( error => {
                    window.notify.apiError(error);
                })

            }
        }

    }
</script>
<style lang="scss">
    .bill_account {
        border: 1px dotted;
        border-radius: 5px;
        margin: 10px 0;
        padding:5px;

        .actions {
            button {
                margin: 5px;
            }
        }
    }
</style>
