<script>
import axios from "axios";
import {
    faSpinner
} from "@fortawesome/free-solid-svg-icons";

export default {
    props: {
        type: {
            type: String,
            default: "button",
        },
        buttonText: {
            type: String,
            default: "Confirm",
        },
        disableButton: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            gettingConfirmStatus: false,
            showConfirmModal: false,
            passwordConfirmationAttempt: "",
            faSpinner,
        }
    },

    methods: {
        async check() {
            try {
                this.gettingConfirmStatus = true;
                const statusResponse = await axios.get("/api/auth/user/confirmed-password-status");
                const confirmed = statusResponse.data.confirmed ?? false;
                this.gettingConfirmStatus = false;
                if(confirmed) {
                    this.$emit('confirmed');
                    return;
                }
                this.showConfirmModal = true;
            }catch(err) {
                console.log(err);
                window.notify.error('Unable to confirm password status', 'Unable to confirm');
            }
        },
        async confirmPassword() {
            try {
                const response = await axios.post("/api/auth/user/confirm-password",
                {
                    password: this.passwordConfirmationAttempt
                });

                if(response.status !== 201) {
                    window.notify.error("Invalid response for password confirmation");
                    return;
                }

                this.showConfirmModal = false;
                this.passwordConfirmationAttempt = "";
                this.$emit("confirmed");
            }catch(err) {
                console.log(err);

                if(err.response.status === 422) {
                    window.notify.error("Incorrect password");
                }
            }
        }
    },
};
</script>
<template>
    <div>
        <div v-if="type === 'slot'" @click="check">
            <slot></slot>
        </div>
        <div v-else-if="type === 'button'">
            <button
                class="button"
                :disabled="disableButton || gettingConfirmStatus"
                @click="check"
            >
        <span v-if="gettingConfirmStatus">
          <font-awesome-icon :icon="faSpinner" :spin="true"></font-awesome-icon>
        </span>
                {{ buttonText }}
            </button>
        </div>

        <modal v-if="showConfirmModal" @close="showConfirmModal = false">
            <template slot="header">
                <h3>Confirm your password</h3>
            </template>
            <template slot="body">
                <form @submit.prevent="confirmPassword">
                    <p>Please confirm your password to continue</p>
                    <form-password
                        ref="passwordConfirmField"
                        v-model="passwordConfirmationAttempt"
                        field-name="confirm-password"
                        style="border 1px solid black"
                    ></form-password>

                    <button class="button"
                    >Confirm Password</button
                    >
                </form>
            </template>
        </modal>
    </div>
</template>
<style>
    input[type=password] {
        border: 1px solid black !important;
    }
</style>
