export default {

    props: {
        validateGeneration: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hasGenerationValidationError: false,
        }
    },

    methods: {
        validateFinalSelectionFormGeneration() {

            if(!this.validateGeneration) {
                return new Promise( resolve => { resolve(true)});
            }

            return new Promise((resolve, reject) => {

                axios.get('/api/projects/' + this.project.id + '/validateFinalSelectionFormGeneration', {
                    params: {
                        phase: this.reportPhase.id,
                        scenario: this.reportScenarioId
                    }
                }).then(response => {
                    if(response.data.is_valid) {
                        resolve(true);
                    }else {
                        reject('invalid');
                    }

                }).catch(error => {
                    window.notify.apiError(error);
                    reject('apiError');
                })

            })
        }
    }
}
