<template>
    <div>
         <div class="col-md-12 panel my-2" v-if="eligibleTextString">
             <div class="panel-body">
                 <h4>Eligibility Requirements:</h4>
                 <span v-html="eligibleTextString"></span>
             </div>
         </div>
    </div>
</template>
<script>
    import { FormFieldMixin } from 'laravel-vue-forms';
    import axios from 'axios';

    export default {

        mixins: [
            FormFieldMixin
        ],

        data() {
            return {
                eligibleText: null
            }
        },

        created() {

            let measureId = this.form.data.measure_id;
            let projectId = this.form.data.project_id;

            axios.get('/api/measures/' + measureId + '/eligibility_text', {
                params: {
                    projectId
                }
            }).then(response => {
                if(response.data.id) {
                    this.eligibleText = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },

        computed: {
            eligibleTextString() {

                if(!this.eligibleText) {
                    return null;
                }

                switch(this.fieldConfig.field_extra.type) {
                    case 'equipment':
                        return this.eligibleText.eligibility_requirements_text_equip;
                    case 'project_measure':
                        return this.eligibleText.eligibility_requirements_text_form;
                    default:
                        return null;
                }
            }
        }

    }
</script>
