<template>
    <div>
        <div v-show="loadingInspectionData">
            <span class="fa fa-spinner fa-spin"></span>
        </div>
        <div v-show="!loadingInspectionData">
            <div v-if="inspection" class="vue-form text-center">
                <h3>Manage Inspection</h3>
                <form-select
                    field-name="inspector"
                    v-model="inspectionReasonId"
                    label="Inspection Reason"
                    :show-label="false"
                    :options="inspectionReasons"
                    option-label-field="name"
                    option-value-field="id"
                    :show-multiselect-labels="false"
                    :disabled="1"
                ></form-select>
                <form-wysiwyg
                    v-if="hasOtherReason"
                    field-name="inspection_other_reason"
                    :value="inspectionReasonOther"
                    :disabled="1"
                >
                </form-wysiwyg>
                <form-select
                    field-name="inspector"
                    v-model="newInspectorId"
                    label="Select an inspector to assign"
                    :show-label="false"
                    :options="inspectors"
                    option-label-field="username"
                    option-value-field="id"
                    :show-multiselect-labels="false"
                ></form-select>
                <div>
                    <button class="button m-2" @click="assignInspector">Reassign Inspection</button>
                    <button class="button m-2" @click="rewindInspection" v-can="'rewind_inspections'">Rewind Inspection</button>
                </div>
            </div>
            <div v-else class="vue-form">
                <h3>#2 Project Verification</h3>
                <form-select
                    field-name="inspector"
                    v-model="inspectionReasonId"
                    label="Select a reason for or against verification"
                    :show-label="false"
                    :options="inspectionReasons"
                    option-label-field="name"
                    option-value-field="id"
                    :show-multiselect-labels="false"
                ></form-select>
                <form-select
                    v-show="inspectionIsRequired"
                    field-name="inspector"
                    v-model="newInspectorId"
                    label="Select an inspector to assign"
                    :show-label="false"
                    :options="inspectors"
                    option-label-field="username"
                    option-value-field="id"
                    :show-multiselect-labels="false"
                ></form-select>
                <button class="button" @click="assignAndOpenInspection">{{ saveText }}</button>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            inspectionPhase: {
                required: true,
                type: Object
            },
            projectId: {
                required: true,
                type: Number,
            },
            currentInspectorId: {
                type: Number
            },
            currentInspectionReasonId: {
                type: Number
            },
            currentInspectionReasonOther: {
                type: String
            }
        },

        data() {
            return {
                newInspectorId: null,
                inspectionReasonId: null,
                inspectionReasonOther: null,
                loadingInspectionData: false,
                inspectionReasons: [],
            }
        },

        computed: {
            inspection() {
                return this.$store.getters['inspections/getInspectionForProjectAndPhase'](this.projectId, this.inspectionPhase.id);
            },
            inspectors() {
                return this.$store.state.inspections.inspectors;
            },
            inspectionIsRequired() {

                let inspectionNotRequiredIndex = this.inspectionReasons.findIndex(inspectionReason => {
                    return inspectionReason.name === 'Not Required' && inspectionReason.id === this.inspectionReasonId
                });

                if(inspectionNotRequiredIndex !== -1 || !this.inspectionReasonId) {
                    return false;
                }

                return true;
            },
            hasOtherReason() {

                let inspectionReasonIsOther = this.inspectionReasons.findIndex(inspectionReason => {
                    return inspectionReason.name === 'Other' && inspectionReason.id === this.inspectionReasonId
                });


                if(inspectionReasonIsOther !== -1) {
                    return true;
                }

                return false;
            },
            saveText() {

                if(this.inspectionIsRequired) {
                    return 'Assign Verification';
                }

                return 'Save';
            }
        },

        watch: {
            'inspection': function(inspection) {

            }
        },

        created() {

            this.loadingInspectionData = true;
            this.loadInspectionData().then(finished => {
                this.loadingInspectionData = false;
                this.newInspectorId = this.currentInspectorId;
                this.inspectionReasonId = this.currentInspectionReasonId;
                this.inspectionReasonOther = this.currentInspectionReasonOther;
            }).catch(error => {
                this.loadingInspectionData = false;
                console.error(error);
                window.notify.message('Unable to load inspection data', 'error');
            });

        },


        methods: {
            async loadInspectionData() {

                await this.$store.dispatch('inspections/getInspectors');
                await this.$store.dispatch('inspections/getProjectInspection', {
                    project_id: this.projectId,
                    phase_id: this.inspectionPhase.id
                });

                let response = await axios.get('/api/projects/' + this.projectId + '/lookups?model=inspections.reason');
                this.inspectionReasons = response.data;

                return true;
            },
            assignAndOpenInspection() {

                if(!this.newInspectorId && this.inspectionIsRequired) {
                    window.notify.message('Please select an inspector', 'error');
                    return;
                }

                this.processSave(this.inspectionIsRequired).then(response => {
                    if (this.inspectionIsRequired) {
                        window.notify.message('Successfully assigned and created inspection', 'success');
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        window.notify.message('Inspection not required', 'success');
                    }
                });
            },
            async processSave(inspectionIsRequired) {

                let response = await axios.post('/api/projects/' + this.projectId + '/inspections/reason', {
                    phase_id: this.inspectionPhase.id,
                    inspectionReasonId: this.inspectionReasonId,
                    inspectionReasonOther: this.inspectionReasonOther,
                });

                if(inspectionIsRequired) {
                    await this.$store.dispatch('inspections/assignAndOpenInspection', {
                        projectId: this.projectId,
                        phaseId: this.inspectionPhase.id,
                        inspectorId: this.newInspectorId,
                        inspectionReasonId: this.inspectionReasonId
                    });
                }


                return true;
            },
            assignInspector() {
                this.$store.dispatch('inspections/reAssignInspector', {
                    projectId: this.projectId,
                    inspectionId: this.inspection.id,
                    inspectorId: this.newInspectorId,
                }).then(response => {

                    window.notify.message('Successfully re-assigned inspection', 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });
            },
            rewindInspection() {
                this.$store.dispatch('inspections/rewindInspection', {
                    projectId: this.projectId,
                    inspectionId: this.inspection.id
                }).then(response => {

                    window.notify.message('Successfully rewound inspection', 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                });
            }
        }

    }
</script>
