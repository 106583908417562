<script>
import {FormFieldMixin} from 'laravel-vue-forms';
import Parser from 'etap/admin/classes/jsonapi_parser'
import {get} from "lodash"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

export default {

    mixins: [FormFieldMixin],
    components: {
        FontAwesomeIcon
    },

    data() {

        return {
            subFormConfig: null,
            subFormLoadedData: null,
            urlParamsToGet: [],
            loading: true,
            spinnerIcon: faSpinner
        }
    },

    async created() {

        if(!this.fieldConfig.field_extra.form_configuration) {
            throw new Error("Invalid form configuration for sub-form");
        }

        await this.getFormConfiguration(this.fieldConfig.field_extra.form_configuration);

        if(this.fieldConfig.field_extra.loadData) {
            await this.loadDataFromUrl();
        }

        this.loading = false;
    },

    computed: {
        loadDataUrl() {
            if(!this.fieldConfig.field_extra.loadData) {
                return '';
            }

            let baseUrl = this.fieldConfig.field_extra.loadData;
            this.urlParamsToGet.forEach(match => {
                let fieldValue = get(this.form.data, match[1], "");
                if (!fieldValue) {
                    fieldValue = "";
                }
                baseUrl = baseUrl.replace(match[0], fieldValue);
            });
            return baseUrl;
        },
        subFormData() {
            if(this.subFormLoadedData) {
                return this.subFormLoadedData
            }

            return this.value ?? {};
        },
        formSubmitUrl() {
            let pattern = /:([^:]*):/g,
                match;
            let submitParamsToMatch = [];
            while ((match = pattern.exec(this.fieldConfig.field_extra.formSubmitUrl))) {
                submitParamsToMatch.push(match);
            }

            let submitUrl = this.fieldConfig.field_extra.formSubmitUrl;
            this.urlParamsToGet.forEach(match => {
                let fieldValue = get(this.form.data, match[1], "");
                if (!fieldValue) {
                    fieldValue = "";
                }
                submitUrl = submitUrl.replace(match[0], fieldValue);
            });

            return submitUrl;
        },
        headerText() {
            return this.fieldConfig.field_extra.headerText
        },
        headerTag() {
            return this.fieldConfig.field_extra.headerTag ?? 'div';
        }
    },

    methods: {
        async getFormConfiguration(formConfigName) {

            let formConfigResponse = await this.apiClient.get('/api/forms/configuration', {
                params: {
                    formConfigName
                }
            });
            this.subFormConfig = Parser.parseJSONAPIResponse(formConfigResponse.data);
        },
        async loadDataFromUrl() {
            let pattern = /:([^:]*):/g,
                match;
            while ((match = pattern.exec(this.fieldConfig.field_extra.loadData))) {
                this.urlParamsToGet.push(match);
            }

            const subFormDataResponse = await this.apiClient.get(this.loadDataUrl);
            this.subFormLoadedData = subFormDataResponse.data;
        },
        async updateSubFormData(data) {
            this.subFormLoadedData = data;
        }
    },
}
</script>
<template>
    <div>

        <div v-if="headerText">
            <component :is="this.headerTag">{{ this.headerText }}</component>
        </div>

        <div v-if="loading">
            Loading... <font-awesome-icon :icon="spinnerIcon" :spin="true"></font-awesome-icon>
        </div>
        <div v-else>
            <vue-form
                :form-data="this.subFormData"
                :form-config="this.subFormConfig"
                :form-submit-url="this.formSubmitUrl"
                :actions="[
                    {
                        name: 'submit',
                        label: 'Submit',
                        action: 'submitForm',
                    }
                ]"
                @created="updateSubFormData"
            ></vue-form>
        </div>


    </div>
</template>
