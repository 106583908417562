<script>
import {byString} from "laravel-vue-forms";
import axios from "axios";

export default {
    props: {
        record: {
            required: true,
            type: Object,
        },
        args: {
            required: true,
            type: Object,
        }
    },

    computed: {
        eligibilityFeature() {
            return this.args.eligibilityFeature;
        },
        displayFieldValues() {
            let displayFieldValues = [];
            if(!this.eligibilityFeature || !this.eligibilityFeature.enabled || !this.eligibilityFeature.options.display_fields) {
                return displayFieldValues;
            }
            displayFieldValues = this.eligibilityFeature.options.display_fields.map(displayField => {
                return byString(this.record, displayField.value_field);
            });
            return displayFieldValues;
        },
    },

    methods: {
        handleProjectCreation(billAccount) {
            if(
                !this.args.programTrackId ||
                !this.args.programYearId
            ) {
                window.notify.error("Unable to create project.   Missing required data.");
                return;
            }

            axios.post('/api/projects/create', {
                program_track_id: this.args.programTrackId,
                program_year_id: this.args.programYearId,
                bill_account_data: this.record,
            }).then((res) => {
                this.isDisabled = false;
                if(res.data.active_url) {
                    window.location.replace(res.data.active_url);
                }
            }).catch((error) => {
                window.notify.apiError(error);
            });
        }
    }
}
</script>
<template>
    <tr>
        <td v-for="displayFieldValue in displayFieldValues">
            {{ displayFieldValue }}
        </td>
        <td>
            <button class="inputbutton1" @click="handleProjectCreation">
                <span class="fa fa-plus"></span>
                Create Project
            </button>
        </td>
    </tr>
</template>
